import { dew as _cursorDew } from "./cursor";
import { dew as _opsDew } from "./ops";
import { dew as _utilDew } from "./util";
import { dew as _renderDew } from "./image/render";
import { dew as _pdfDew } from "./image/pdf";
import { dew as _jpegDew } from "./image/jpeg";
import { dew as _alreadyEndedDew } from "./errors/already-ended";
import { dew as _textDew } from "./text";
import { dew as _cellDew } from "./cell";
import { dew as _tableDew } from "./table";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const Cursor = _cursorDew();
  const ops = _opsDew();
  const util = _utilDew();
  const renderImage = _renderDew();
  const PDFImage = _pdfDew();
  const JPEGImage = _jpegDew();
  const AlreadyEndedError = _alreadyEndedDew();
  exports = class Fragment {
    constructor(doc, parent) {
      this._init(doc, parent);
    }

    /// private API

    _init(doc, parent) {
      this._doc = doc;
      this._parent = parent;
      this._cursor = parent._cursor;
      this._ended = false;
      this._current = null;
      this._pending = parent._pending;
    }
    async _pageBreak(level) {
      if (this._parent) {
        await this._parent._pageBreak(level + 1);
      }
    }
    async _end() {
      // abstract
    }
    _begin(ctx) {
      if (this._ended) {
        throw new AlreadyEndedError();
      }
      if (this._current) {
        this._current.end();
      }
      this._current = ctx;
    }
    _opts(opts) {
      if (this.opts) {
        // inherit font options
        return Object.assign({
          font: this.opts.font,
          fontSize: this.opts.fontSize,
          color: this.opts.color,
          lineHeight: this.opts.lineHeight
        }, opts);
      } else {
        return opts;
      }
    }

    /// public API

    end() {
      if (this._ended) {
        throw new AlreadyEndedError();
      }
      if (this._current) {
        this._current.end();
        this._current = null;
      }
      this._ended = true;
      this._pending.push(() => this._end());
    }
    text(text, opts) {
      if (text !== null && typeof text === "object") {
        opts = text;
        text = undefined;
      }
      if (!opts || typeof opts !== "object") {
        opts = {};
      }
      const Text = _textDew();
      const ctx = new Text(this._doc, this, this._opts(opts));
      this._begin(ctx);
      ctx._pending.push(() => ctx._start());
      if (typeof text === "string" && text.length > 0) {
        ctx.add(text);
      }
      return ctx;
    }
    cell(text, opts) {
      if (text !== null && typeof text === "object") {
        opts = text;
        text = undefined;
      }
      if (!opts || typeof opts !== "object") {
        opts = {};
      }
      const Cell = _cellDew();
      const ctx = new Cell(this._doc, this, this._opts(opts));
      this._begin(ctx);
      ctx._pending.push(() => ctx._start());
      this._pending.push(ctx._pending);
      if (typeof text === "string" && text.length > 0) {
        ctx.text(text, opts);
      }
      return ctx;
    }
    table(opts) {
      if (!opts || typeof opts !== "object") {
        opts = {};
      }
      const Table = _tableDew();
      const ctx = new Table(this._doc, this, this._opts(opts));
      this._begin(ctx);
      return ctx;
    }
    image(img, opts) {
      if (!(img instanceof JPEGImage) && !(img instanceof PDFImage)) {
        throw new TypeError("Expected an image object");
      }
      if (!opts || typeof opts !== "object") {
        opts = {};
      }
      this._begin(null);
      this._pending.push(() => renderImage(img, this._doc, this, opts));
    }
    pageBreak() {
      this._begin(null);
      this._pending.push(() => this._parent._pageBreak(1));
    }
    op(fn) {
      this._begin(null);
      this._pending.push(async () => {
        if (!this._doc._currentContent) {
          await this._doc._startPage();
        }
        let args = arguments;
        if (typeof fn === "function") {
          args = fn(this._cursor.x, this._cursor.y);
          if (!Array.isArray(args)) {
            throw new TypeError("Return of .op(() => {}) must be an array");
          }
        }
        return this._doc._write(ops.write.apply(ops, args));
      });
    }
    destination(name) {
      this._begin(null);
      this._pending.push(async () => {
        const DestinationRangeStyle = _textDew().DestinationRangeStyle;
        const self = {
          destination: name,
          doc: this._doc,
          from: this._cursor.x,
          y: this._cursor.y
        };
        DestinationRangeStyle.prototype._applyStyle.call(self);
      });
    }
  };
  return exports;
}