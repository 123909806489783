export default {
  "name": "pdfjs",
  "author": "Markus Ast <npm.m@rkusa.st>",
  "version": "2.5.2",
  "description": "A Portable Document Format (PDF) generation library targeting both the server- and client-side.",
  "keywords": [
    "pdf",
    "generator"
  ],
  "license": "MIT",
  "homepage": "https://github.com/rkusa/pdfjs",
  "bugs": "https://github.com/rkusa/pdfjs/issues",
  "main": "lib/index.js",
  "scripts": {
    "fmt": "prettier --write './{lib,test}/**/*.js'",
    "test": "npm run test:pdfs && npm run test:types",
    "test:pdfs": "node test/index.js",
    "test:types": "tsc --project ./types"
  },
  "dependencies": {
    "@rkusa/linebreak": "^1.0.0",
    "opentype.js": "^1.3.3",
    "pako": "^2.0.3",
    "readable-stream": "^3.6.0",
    "unorm": "^1.6.0",
    "uuid": "^8.3.1"
  },
  "devDependencies": {
    "@types/node": "^15.12.4",
    "buffer": "^6.0.3",
    "events": "^3.3.0",
    "prettier": "^3.0.0",
    "tape": "^5.0.1",
    "typescript": "^4.0.3"
  },
  "repository": {
    "type": "git",
    "url": "git://github.com/rkusa/pdfjs.git"
  },
  "engines": {
    "node": ">=7"
  },
  "types": "./types/main.d.ts"
}
;