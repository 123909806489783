import { dew as _documentDew } from "./document";
import { dew as _otfDew } from "./font/otf";
import { dew as _imageDew } from "./image/image";
import { dew as _externalDew } from "./external";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.Document = _documentDew();
  exports.Font = _otfDew();
  exports.Image = _imageDew();
  exports.ExternalDocument = _externalDew();
  exports.mm = 0.0393700787 * 72;
  exports.cm = exports.mm * 10;
  return exports;
}