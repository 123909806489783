import { dew as _utilDew } from "../util";
import { dew as _pdfDew } from "./pdf";
import { dew as _jpegDew } from "./jpeg";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const util = _utilDew();
  const PDFImage = _pdfDew();
  const JPEGImage = _jpegDew();
  exports = class Image {
    constructor(b) {
      const src = util.toArrayBuffer(b);
      switch (determineType(src)) {
        case "pdf":
          return new PDFImage(src);
        case "jpeg":
          return new JPEGImage(src);
        default:
          throw new TypeError("Unsupported image type");
      }
    }
  };
  function determineType(buffer) {
    const pdf = String.fromCharCode.apply(null, new Uint8Array(buffer, 0, 5));
    if (pdf === "%PDF-") {
      return "pdf";
    }
    const view = new DataView(buffer);
    if (view.getUint8(0) === 255 || view.getUint8(1) === 216) {
      return "jpeg";
    }
    return null;
  }
  return exports;
}