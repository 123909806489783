import { dew as _objectDew } from "../object";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const PDF = _objectDew();
  exports = class JPEGImage {
    constructor(src) {
      this.src = src;
      this.xobjCount = 1;
      const view = new DataView(src);
      if (view.getUint8(0) !== 255 || view.getUint8(1) !== 216) {
        throw new Error("Invalid JPEG image.");
      }
      let blockLength = view.getUint8(4) * 256 + view.getUint8(5);
      const len = view.byteLength;
      let i = 4;
      while (i < len) {
        i += blockLength;
        if (view.getUint8(i) !== 255) {
          throw new Error("Could not read JPEG the image size");
        }
        if (view.getUint8(i + 1) === 192 ||
        //(SOF) Huffman  - Baseline DCT
        view.getUint8(i + 1) === 193 ||
        //(SOF) Huffman  - Extended sequential DCT
        view.getUint8(i + 1) === 194 ||
        // Progressive DCT (SOF2)
        view.getUint8(i + 1) === 195 ||
        // Spatial (sequential) lossless (SOF3)
        view.getUint8(i + 1) === 196 ||
        // Differential sequential DCT (SOF5)
        view.getUint8(i + 1) === 197 ||
        // Differential progressive DCT (SOF6)
        view.getUint8(i + 1) === 198 ||
        // Differential spatial (SOF7)
        view.getUint8(i + 1) === 199) {
          this.height = view.getUint8(i + 5) * 256 + view.getUint8(i + 6);
          this.width = view.getUint8(i + 7) * 256 + view.getUint8(i + 8);
          const colorSpace = view.getUint8(i + 9);
          switch (colorSpace) {
            case 3:
              this.colorSpace = "DeviceRGB";
              break;
            case 1:
              this.colorSpace = "DeviceGray";
              break;
          }
          break;
        } else {
          i += 2;
          blockLength = view.getUint8(i) * 256 + view.getUint8(i + 1);
        }
      }
    }
    async write(doc, xobjs) {
      const xobj = xobjs[0];
      xobj.prop("Subtype", "Image");
      xobj.prop("Width", this.width);
      xobj.prop("Height", this.height);
      xobj.prop("ColorSpace", this.colorSpace);
      xobj.prop("BitsPerComponent", 8);
      const hex = asHex(this.src);
      xobj.prop("Filter", new PDF.Array(["/ASCIIHexDecode", "/DCTDecode"]));
      xobj.prop("Length", hex.length + 1);
      xobj.prop("Length1", this.src.byteLength);
      const content = new PDF.Stream(xobj);
      content.content = hex + ">\n";
      await doc._writeObject(xobj);
    }
  };
  function asHex(ab) {
    const view = new Uint8Array(ab);
    let hex = "";
    for (let i = 0, len = ab.byteLength; i < len; ++i) {
      hex += toHex(view[i]);
    }
    return hex;
  }
  function toHex(n) {
    if (n < 16) return "0" + n.toString(16);
    return n.toString(16);
  }
  return exports;
}