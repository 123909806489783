import { dew as _objectDew } from "./object";
var exports = {},
  _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const PDF = _objectDew();
  exports = class ContentChunk {
    constructor(doc, obj) {
      this._doc = doc;
      this._fonts = {};
      this._xobjects = {};
      this._object = obj || new PDF.Object();
      this._length = new PDF.Object();
      doc._registerObject(this._object);
      doc._registerObject(this._length);
      this._object.prop("Length", this._length.toReference());
    }
  };
  return exports;
}